import styled from '@emotion/styled';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

export enum TYPE_LABEL_KEY {
  EVENT = 'EVENT',
  RESERVATION_AVAILABLE = 'RESERVATION_AVAILABLE',
  OVER_NIGHT = 'OVER_NIGHT',
  IS_ALLIANCE = 'IS_ALLIANCE',
  PROMOTION = 'PROMOTION',
}

const TYPE_LABEL_MAP = {
  [TYPE_LABEL_KEY.EVENT]: {
    text: '이벤트',
    backgroundColor: GLOBAL_COLOR.RED_50,
    color: GLOBAL_COLOR.RED_500,
  },
  [TYPE_LABEL_KEY.RESERVATION_AVAILABLE]: {
    text: '간편예약',
    backgroundColor: GLOBAL_COLOR.BLUE_30,
    color: GLOBAL_COLOR.BLUE_400,
  },
  [TYPE_LABEL_KEY.OVER_NIGHT]: {
    text: '야간진료',
    backgroundColor: '#F3ECFF',
    color: '#6D14FF',
  },
  [TYPE_LABEL_KEY.IS_ALLIANCE]: {
    text: '핏펫추천',
    backgroundColor: GLOBAL_COLOR.GRAY_50,
    color: GLOBAL_COLOR.GRAY_500,
  },
  [TYPE_LABEL_KEY.PROMOTION]: {
    text: '프로모션',
    backgroundColor: '#FFF0E1',
    color: GLOBAL_COLOR.ORANGE_500,
  },
};

interface TypeLabelProps {
  type: keyof typeof TYPE_LABEL_KEY;
}

const TypeLabel = ({ type }: TypeLabelProps) => {
  const { text, backgroundColor, color } = TYPE_LABEL_MAP[type];

  return (
    <HospitalTypeLabel color={color} backgroundColor={backgroundColor}>
      {text}
    </HospitalTypeLabel>
  );
};

export default TypeLabel;

const HospitalTypeLabel = styled.span<{ color: string; backgroundColor: string }>`
  display: flex;
  align-items: center;
  padding: ${toRem(2)} ${toRem(4)};
  border-radius: ${toRem(4)};
  font-size: ${toRem(12)};
  line-height: ${toRem(16)};
  font-weight: 500;
  text-align: center;
  ${(props) => ({
    color: props.color,
    backgroundColor: props.backgroundColor,
  })};
`;
