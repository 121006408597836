import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import HospitalStarReview from './HospitalStarReview';
import { Hospital } from '@/api/hospital';
import FlatIcon from '@/components/common/FlatIcon';
import ImageContent from '@/components/common/ImageContent';
import TypeLabel, { TYPE_LABEL_KEY } from '@/components/common/TypeLabel';
import useHospitalScrapStore from '@/components/hospital/useHospitalScrapStore';
import { Body2 } from '@/components/typeface/Body2';
import { Body3 } from '@/components/typeface/Body3';
import { Body4 } from '@/components/typeface/Body4';
import { H6 } from '@/components/typeface/H6';
import { BASIC_EVENT_TEXT } from '@/constants/common';
import { PROMOTION_EVENT_HOSPITAL_LIST } from '@/constants/policy';
import { FONT_WEIGHT } from '@/constants/typeface';
import { GLOBAL_COLOR } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';
import { checkIsIntolinkHospital, getPromotionEventHospital, toRem } from '@/utils/commonUtils';
import { getWorkingTime, WORKING_STATUS, WORKING_STATUS_TEXT } from '@/utils/dateUtils';

interface HospitalCardProps {
  data: Hospital;
  isHidingBorderBottom?: boolean;
  onCardClick: (hospital: Hospital) => void;
  onScrapClick: (hospital: Hospital) => void;
  isShortAddress?: boolean;
  className?: string;
}

const SLIDER_SETTINGS = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  autoplay: true,
  autoplaySpeed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  centerPadding: '0px',
  vertical: true,
  verticalSwiping: false,
} as const;

const HospitalCard = ({
  data,
  isHidingBorderBottom = false,
  onCardClick,
  onScrapClick,
  isShortAddress,
  className,
}: HospitalCardProps) => {
  const {
    id,
    name,
    image,
    isAlliance,
    isEvent,
    totalScore,
    reviewCount,
    expertise,
    distance,
    workingDay,
    shortAddress,
    isTemporaryClose,
    intoLinkStatus,
    isReservationPossible,
  } = data;
  const { workingTime, workingStatus, workingStatusText } = getWorkingTime({ workingDay, isTemporaryClose });
  const { checkHospitalScrap } = useHospitalScrapStore();
  const isScrap = checkHospitalScrap(id);
  const isIntolinkHospital = checkIsIntolinkHospital(intoLinkStatus);

  const workingStatusKr = useMemo(() => {
    if (workingStatus === WORKING_STATUS.TEMPORARY) {
      return WORKING_STATUS_TEXT.TEMPORARY;
    }
    if (workingStatus === WORKING_STATUS.HOLIDAY) {
      return workingStatusText;
    }
    if (workingStatus === WORKING_STATUS.OPEN) {
      return WORKING_STATUS_TEXT.OPEN;
    }
    if (workingStatus === WORKING_STATUS.CLOSED) {
      return WORKING_STATUS_TEXT.CLOSED;
    }
    return WORKING_STATUS_TEXT.EMPTY_SHORT;
  }, [workingStatus, workingStatusText]);

  const promotionEventHospital = getPromotionEventHospital({
    hospitalList: PROMOTION_EVENT_HOSPITAL_LIST,
    hospitalId: data.id,
  });

  return (
    <Container isHidingBorderBottom={isHidingBorderBottom} onClick={() => onCardClick(data)} className={className}>
      <ContentContainer>
        <InfoTextContainer>
          <div>
            <div className={'titleWrapper'}>
              <H6 className={'ellipsis'} fontWeight={700} fontColor={GLOBAL_COLOR.GRAY_900}>
                {name}
              </H6>
            </div>
            <div className={'timeContainer'}>
              <Body2
                fontColor={workingStatus === WORKING_STATUS.OPEN ? GLOBAL_COLOR.BLUE_500 : GLOBAL_COLOR.GRAY_300}
                fontWeight={500}>
                {workingStatusKr}
              </Body2>
              {!(
                workingTime === WORKING_STATUS_TEXT.EMPTY ||
                workingStatus === WORKING_STATUS.TEMPORARY ||
                workingStatus === WORKING_STATUS.HOLIDAY
              ) && (
                <div style={{ marginLeft: toRem(8) }}>
                  <Body2 fontColor={GLOBAL_COLOR.GRAY_900}>{workingTime}</Body2>
                </div>
              )}
              <i className={'line'} />
              <Body2 fontColor={GLOBAL_COLOR.GRAY_700}>
                {isShortAddress ? shortAddress : `${distance?.toFixed(1) ?? 0}km`}
              </Body2>
            </div>
            {expertise?.length > 0 && (
              <div className={'expertiseContainer'}>
                <Body4 className={'ellipsis'} fontWeight={500} fontColor={GLOBAL_COLOR.GRAY_300}>
                  {`${expertise?.join('ㆍ')} `}
                </Body4>
              </div>
            )}
            <HospitalStarReview
              totalScore={totalScore}
              reviewCount={reviewCount}
              iconSize={12}
              fontSize={13}
              lineHeight={18}
            />
          </div>

          <TypeLabelContainer>
            {isReservationPossible && !isIntolinkHospital && <TypeLabel type={TYPE_LABEL_KEY.RESERVATION_AVAILABLE} />}
            {isAlliance && !isIntolinkHospital && <TypeLabel type={TYPE_LABEL_KEY.IS_ALLIANCE} />}
            {isEvent && <TypeLabel type={TYPE_LABEL_KEY.EVENT} />}
            {promotionEventHospital?.isPromotion && <TypeLabel type={TYPE_LABEL_KEY.PROMOTION} />}
          </TypeLabelContainer>
        </InfoTextContainer>
        <ImageWrapper>
          <ScrapWrapper
            onClick={(e) => {
              e.stopPropagation();
              onScrapClick(data);
            }}
            isScrap={isScrap}>
            <FlatIcon
              iconType={isScrap ? 'icScrapFill' : 'icScrap'}
              size={24}
              color={isScrap ? GLOBAL_COLOR.BLUE_300 : GLOBAL_COLOR.WHITE}
            />
          </ScrapWrapper>
          <ImageContent url={image} isThumbnail={true} />
        </ImageWrapper>
      </ContentContainer>
      {isReservationPossible && !promotionEventHospital && (
        <EventWrapper>
          <FlatIcon iconType={'icEventFlat'} size={16} />
          <EventTitle>이벤트</EventTitle>
          <EventText>{BASIC_EVENT_TEXT}</EventText>
        </EventWrapper>
      )}

      {/* 프로모션 롤링 텍스트 */}
      {isReservationPossible && promotionEventHospital && (
        <SliderEventWrapper>
          <Slider {...SLIDER_SETTINGS}>
            <EventContentWrapper>
              <EventContent>
                <FlatIcon iconType={'icPromotionFlat'} size={16} />
                <EventTitle>프로모션</EventTitle>
                <EventText>{promotionEventHospital.listPromotionText}</EventText>
              </EventContent>
            </EventContentWrapper>
            <EventContentWrapper>
              <EventContent>
                <FlatIcon iconType={'icEventFlat'} size={16} />
                <EventTitle>이벤트</EventTitle>
                <EventText>{BASIC_EVENT_TEXT}</EventText>
              </EventContent>
            </EventContentWrapper>
          </Slider>
        </SliderEventWrapper>
      )}
    </Container>
  );
};

export default React.memo(HospitalCard);

const Container = styled.div<{ isHidingBorderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${toRem(16)} 0;
  border-bottom: ${({ isHidingBorderBottom }) =>
    isHidingBorderBottom ? undefined : `1px solid ${GLOBAL_COLOR.GRAY_50}`};
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  margin-right: ${toRem(12)};
  height: auto;

  .titleWrapper {
    margin-bottom: ${toRem(8)};
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .timeContainer {
    display: flex;
    margin-bottom: ${toRem(8)};
  }

  .expertiseContainer {
    margin-bottom: ${toRem(8)};
  }

  .likePercent {
    display: flex;
    align-items: center;
    gap: ${toRem(4)};
  }

  .line {
    display: flex;
    align-self: center;
    right: 45px;
    background: ${GLOBAL_COLOR.GRAY_100};
    width: 1px;
    height: ${toRem(12)};
    margin: 0 ${toRem(8)};
    border-radius: ${toRem(2)};
  }
`;

const TypeLabelContainer = styled.div`
  margin-top: ${toRem(12)};
  display: flex;
  column-gap: ${toRem(4)};
`;

const ImageWrapper = styled.div`
  width: ${toRem(109)};
  height: ${toRem(109)};
  border: 1px solid ${GLOBAL_COLOR.GRAY_50};
  border-radius: ${toRem(8)};
  overflow: hidden;
  position: relative;
`;

const ScrapWrapper = styled.div(
  ({ isScrap }: { isScrap: boolean }) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: ${Z_INDEX.SCRAP};
    padding: ${toRem(6)};

    svg {
      filter: drop-shadow(0 ${toRem(2)} ${toRem(5)} rgba(0, 0, 0, 0.1));

      ${!isScrap &&
      css`
        fill: ${GLOBAL_COLOR.WHITE};
        fill-opacity: 0.3;
      `};
    }
  `,
);

const CommonEventWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${toRem(12)};
  border: ${toRem(1)} solid ${GLOBAL_COLOR.ORANGE_200};
  border-radius: ${toRem(8)};
`;

const EventWrapper = styled(CommonEventWrapper)`
  padding: ${toRem(8)} ${toRem(12)};
`;

const SliderEventWrapper = styled(CommonEventWrapper)`
  padding: 0 ${toRem(12)};
`;

const EventContentWrapper = styled.div``;

const EventContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${toRem(8)};
  padding-bottom: ${toRem(8)};
`;

const EventTitle = styled(Body3)`
  color: ${GLOBAL_COLOR.ORANGE_500};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  line-height: ${toRem(20)};
  margin-left: ${toRem(4)};
`;

const EventText = styled(Body3)`
  color: ${GLOBAL_COLOR.ORANGE_500};
  line-height: ${toRem(20)};
  margin-left: ${toRem(8)};
`;
