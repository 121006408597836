import styled from '@emotion/styled';
import FlatIcon from '@/components/common/FlatIcon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { getParsedTotalScore, toRem } from '@/utils/commonUtils';
import { addCommaToNumber } from '@/utils/stringUtils';

interface HospitalStarReviewProps {
  totalScore: number;
  reviewCount: number;
  iconSize: number;
  fontSize: number;
  lineHeight: number;
}

const HospitalStarReview = ({
  totalScore,
  reviewCount,
  iconSize = 12,
  fontSize = 13,
  lineHeight = 18,
}: HospitalStarReviewProps) => {
  if (totalScore <= 0 || reviewCount <= 0) {
    return null;
  }

  return (
    <Container fontSize={fontSize} lineHeight={lineHeight}>
      <TotalScoreContainer>
        <FlatIcon iconType={'icStar'} size={iconSize} color={GLOBAL_COLOR.YELLOW_500} />
        <TotalScore>{getParsedTotalScore(totalScore)} </TotalScore>
      </TotalScoreContainer>
      <ReviewCountContainer>
        {'('}
        <ReviewCount>{addCommaToNumber(reviewCount)}</ReviewCount>
        {')'}
      </ReviewCountContainer>
    </Container>
  );
};

export default HospitalStarReview;

const Container = styled.div<{ fontSize: number; lineHeight: number }>`
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: ${GLOBAL_COLOR.GRAY_500};
  font-size: ${({ fontSize }) => toRem(fontSize)};
  line-height: ${({ lineHeight }) => toRem(lineHeight)};
  white-space: pre-wrap;
`;

const TotalScoreContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalScore = styled.div`
  margin-left: ${toRem(2)};
`;

const ReviewCountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
`;

const ReviewCount = styled.div``;
