import { useCallback, useRef } from 'react';
import { Hospital } from '@/api/hospital';
import useHospitalScrapStore from '@/components/hospital/useHospitalScrapStore';
import { SCRAP_TAB_INDEX } from '@/constants/common';
import { ROUTES, ROUTE_QUERY_KEYS } from '@/constants/routes';
import { TOAST_MARGIN } from '@/constants/toast';
import { SNACKBAR_MESSAGE } from '@/constants/toastMessage';
import { WEB_STORAGE_KEY } from '@/constants/webStorageKey';
import useAppRouter from '@/hooks/useAppRouter';
import { useLoginModal } from '@/hooks/useLoginModal';
import useSnackBar from '@/hooks/useSnackBar';
import { useCreateHospitalScrap } from '@/queries/mutation/useHospitalScrapMutation';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import { setSessionStorageItem } from '@/utils/webStorage';

interface UseHospitalScrapProps {
  onScrapUpdated?: (hospital: Hospital, isAdded: boolean) => void;
  isAboveCTA?: boolean;
}

const useHospitalScrap = ({ onScrapUpdated, isAboveCTA }: UseHospitalScrapProps) => {
  const hospitalMapRef = useRef<Map<number, Hospital>>(new Map<number, Hospital>());

  const handleSuccess = useCallback(
    (hospitalId: number, isAdded: boolean) => {
      const hospital = hospitalMapRef.current.get(hospitalId);
      if (hospital && onScrapUpdated) {
        onScrapUpdated(hospital, isAdded);
      }
    },
    [onScrapUpdated],
  );

  const { isLogin } = useCheckLogin();
  const loginModalProps = useLoginModal();

  const { mutateAsync: toggleHospitalScrapMutate } = useCreateHospitalScrap({ onSuccess: handleSuccess });
  const { addHospitalScrap, deleteHospitalScrap } = useHospitalScrapStore();

  const { showSnackBar } = useSnackBar();
  const router = useAppRouter();

  const handleRollbackDeleteScrap = useCallback(
    (hospitalId: number) => {
      toggleHospitalScrapMutate(hospitalId).then(({ isScrap }) => {
        if (isScrap) {
          addHospitalScrap(hospitalId);
        }
      });
    },
    [addHospitalScrap, toggleHospitalScrapMutate],
  );

  const handleRouteScrapHospital = useCallback(() => {
    setSessionStorageItem(WEB_STORAGE_KEY.MYPAGE_SCRAP_TAB, SCRAP_TAB_INDEX.HOSPITAL.toString());
    router.push({
      pathname: ROUTES.SCRAP.LIST,
      query: { [ROUTE_QUERY_KEYS.PATH_PAGE]: router.pathname },
    });
  }, [router]);

  const handleToggleHospitalScrapMutate = useCallback(
    (hospitalId: number) => {
      toggleHospitalScrapMutate(hospitalId).then(({ isScrap }) => {
        if (isScrap) {
          addHospitalScrap(hospitalId);
          showSnackBar({
            text: SNACKBAR_MESSAGE.HOSPITAL_CREATE_SCRAP,
            onClickText: '바로가기',
            onClick: handleRouteScrapHospital,
            rightIcon: 'icChevronRightSmall',
            margin: isAboveCTA ? TOAST_MARGIN.LARGE : TOAST_MARGIN.SMALL,
          });
        } else {
          deleteHospitalScrap(hospitalId);
          showSnackBar({
            text: SNACKBAR_MESSAGE.HOSPITAL_DELETE_SCRAP,
            onClickText: '되돌리기',
            onClick: () => handleRollbackDeleteScrap(hospitalId),
            rightIcon: 'icRollBack',
            margin: isAboveCTA ? TOAST_MARGIN.LARGE : TOAST_MARGIN.SMALL,
          });
        }
      });
    },
    [
      addHospitalScrap,
      deleteHospitalScrap,
      handleRollbackDeleteScrap,
      handleRouteScrapHospital,
      isAboveCTA,
      showSnackBar,
      toggleHospitalScrapMutate,
    ],
  );

  const toggleHospitalScrapWithCheckLogin = useCallback(
    (hospital: Hospital) => {
      if (!isLogin()) {
        loginModalProps.showModalWithCallback({
          onLogin: () => {},
        });
        return;
      }
      hospitalMapRef.current.set(hospital.id, hospital);
      handleToggleHospitalScrapMutate(hospital.id);
    },
    [handleToggleHospitalScrapMutate, isLogin, loginModalProps],
  );

  return { toggleHospitalScrapWithCheckLogin, handleToggleHospitalScrapMutate };
};

export default useHospitalScrap;
