import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { GLOBAL_COLOR } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';
import { getImageFullUrl, getImageResizeUrl, replaceBrokenThumbnail, toRem } from '@/utils/commonUtils';
import { getWidthHeight, Size } from '@/utils/layoutUtils';

export const DEFAULT_IMAGE = '/images/thumbnail.svg';
export const HOSPITAL_DETAIL_DEFAULT_EMPTY_IMAGE = '/images/empty_thumbnail.svg';

interface ImageConfig {
  width: string;
  height: string;
  borderRadius?: number | string;
  isMoreCover?: boolean;
  moreCount?: number;
  isThumbnail?: boolean;
}

export interface ImageContentProps extends Omit<ImageConfig, 'width' | 'height'> {
  url?: string;
  alt?: string;
  onImageContentClick?: () => void;
  size?: Size;
  isHospitalDetailPage?: boolean;
}

const ImageContent = ({
  url,
  alt,
  size = { width: '100%', height: '100%' },
  isMoreCover,
  moreCount,
  borderRadius = 0,
  isThumbnail = false,
  onImageContentClick,
  isHospitalDetailPage = false,
}: ImageContentProps) => {
  const [width, height] = getWidthHeight(size);
  const borderRadiusStr = typeof borderRadius === 'number' ? toRem(borderRadius) : borderRadius;

  const getImageUrl = useCallback(
    (imageUrl?: string) => {
      if (!imageUrl) {
        if (isHospitalDetailPage) {
          return HOSPITAL_DETAIL_DEFAULT_EMPTY_IMAGE;
        }
        return DEFAULT_IMAGE;
      }
      if (isThumbnail) {
        return getImageResizeUrl(imageUrl);
      }
      return getImageFullUrl(imageUrl);
    },
    [isHospitalDetailPage, isThumbnail],
  );

  return (
    <ImgWrapper width={width} height={height} borderRadius={borderRadiusStr} onClick={onImageContentClick}>
      {isMoreCover && (
        <div className="cover">
          <p>
            <span className="cover__count">+ {moreCount}</span> <br />
            더보기
          </p>
        </div>
      )}
      <img src={getImageUrl(url)} alt={alt || 'fitpet'} onError={replaceBrokenThumbnail} />
    </ImgWrapper>
  );
};

export default React.memo(ImageContent);

export const ImgWrapper = styled.div<ImageConfig>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width};

  > div,
  img {
    border-radius: ${({ borderRadius }) => borderRadius};
  }

  :after {
    display: block;
    width: ${({ width }) => width};
    padding-bottom: ${({ height }) => height};
    content: '';
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cover {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: ${toRem(16)};
    line-height: ${toRem(20)};
    color: ${GLOBAL_COLOR.WHITE};
    z-index: ${Z_INDEX.IMAGE_COVER};

    &__count {
      font-weight: 600;
    }
  }
`;
