import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { HospitalApi } from '@/api/hospital';
import { QUERY_BASE_KEYS, QUERY_KEYWORD } from '@/constants/queryKey';
import { ERROR_MESSAGE } from '@/constants/toastMessage';
import useToast from '@/hooks/useToast';

interface UseCreateHospitalScrapParams {
  onSuccess?: (hospitalId: number, isAdded: boolean) => void;
}

export const useCreateHospitalScrap = ({ onSuccess }: UseCreateHospitalScrapParams) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation(
    async (hospitalId: number) => {
      const response = await HospitalApi.createHospitalScrap(hospitalId);
      return response;
    },
    {
      onSuccess: (response, hospitalId) => {
        queryClient.invalidateQueries(QUERY_BASE_KEYS.HOSPITAL.SCRAPS());
        queryClient.invalidateQueries([QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.SIMPLE_RESERVATION, QUERY_KEYWORD.LIST]);
        onSuccess?.(hospitalId, response.isScrap);
      },
      onError: (error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.log('useCreateHospitalScrap onError', error);
        showToast({ text: ERROR_MESSAGE.TEMPORARY_ERROR });
      },
    },
  );
};
