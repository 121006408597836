import styled from '@emotion/styled';
import { forwardRef, ReactNode } from 'react';
import { POSITION_TYPE } from '@/constants/common';
import { GLOBAL_COLOR } from '@/styles/colors';
import { HEADER_HEIGHT } from '@/styles/sizes';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

interface BaseHeaderContainerProps {
  position?: POSITION_TYPE;
  children: ReactNode;
  className?: string;
}

const BaseHeaderContainer = forwardRef<HTMLDivElement, BaseHeaderContainerProps>(
  ({ position = 'relative', children, className }, ref) => {
    return (
      <Container id={'base-header-wrapper'} position={position} className={className} ref={ref}>
        {children}
      </Container>
    );
  },
);

export default BaseHeaderContainer;

const Container = styled.header<{ position: POSITION_TYPE }>`
  display: flex;
  position: ${({ position }) => position};
  z-index: ${Z_INDEX.BASE_HEADER};
  background-color: ${GLOBAL_COLOR.WHITE};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${GLOBAL_COLOR.GRAY_50};
  width: 100%;
  height: ${toRem(HEADER_HEIGHT)};
`;
